import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';
import FeaturedList from '../components/vehicles/FeaturedList';
import { Heading, Box } from 'grommet';
import { VideoBanner } from '../components/ui';
import HomeBannerImage from '../components/HomeBannerImage';
import { StyledCMS } from '../components/styles';




const IndexPage = () =>
{
  const data = useStaticQuery(graphql`
    query GET_HOME_VEHICLES {
    wpgraphql {
        homepage {
        title
        content
      }
    }
      hyper {
        vehicles(limit: 5, query: { onHomepage: { value: true } }) {
          results {
            model {
              name
            }
            make {
              name
            }
            makeString
            mediaCount
            modelString
            price
            ageIdentifier
            desc
            title
            mileage
            slug
            year
            vehicleType
            variant
            bodyStyle
            color

            doors

            fuelType
            technical {
              electricVehicleBattery {
                capacity
                chargePort
                chargeTime
                batteryType
              }
              performance {
                torque {
                  ftLb
                  nm
                  peak
                }
                power {
                  bhp
                  kw
                  peak
                }
                maxSpeed {
                  mph
                  kph
                }
              }
              dimensions {
                height
                width
                length
              }
              emissions {
                co2
                engineEuroStars
              }
              engine {
                fuelSystem
                fuelDelivery
                aspiration
                cylinders
                camType
                drivenAxle
                arrangement
                capacity
                gears
                steeringWheel
                wheelbase
                valves
                engineLocation
                stroke
                bore
              }
              fuelConsumption {
                mpg
                mpgEU
                mpgCold
              }
            }
            media {
              id
              url
              type
              resourceType
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500 ) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              
            }
            }
            id
          }
        }
      }
    }
  `);

  const { results } = data.hyper.vehicles;
  const { title, content } = data.wpgraphql.homepage;
  return (
    <Layout>
      <SEO title="Home" />
      <VideoBanner videoId="ifK52xh4Wjg" fallback={<HomeBannerImage />} showSearch />
      <Wrapper>
        <Box pad="large">
          <Heading level="1">{title}</Heading>

          <StyledCMS dangerouslySetInnerHTML={{ __html: content }} />
        </Box>

        <FeaturedList results={results} />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
