import React, { useContext } from 'react';
import { Grid, ResponsiveContext, Heading, Box } from 'grommet';
import FeaturedCard from './FeaturedCard';

const FeaturedList = ({ results }) =>
{
  const size = useContext(ResponsiveContext);

  if (!results) {
    return null;
  }
  return (
    <>
      <Box pad="large">
        <Heading level={2} margin="none">
          Featured Cars
        </Heading>
      </Box>
      <Grid
        columns={size === 'small' ? ['1fr'] : ['1fr', '1fr', '1fr']}
        gap={size === 'small' ? 'medium' : 'large'}
        fill="horizontal"
      >
        {results.map((vehicle, i) => (
          <FeaturedCard vehicle={vehicle} key={`veh-${i}`} />
        ))}
      </Grid>
    </>
  );
};

export default FeaturedList;
