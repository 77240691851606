import React from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';

import slugify from 'slugify';
import { Button, Box } from 'grommet';
import styled from 'styled-components';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';
import ListingCard from '../components/vehicles/ListingCard';
import Breadcrumbs from '../components/ui/Breadcrumbs';

import FullSearch from '../components/vehicles/FullSearch';
import { TwoColumn } from '../components/ui';

const StyledPagination = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > * + * {
    margin-left: 16px;
  }
`;

const CarSalesListing = ({ pageContext }) =>
{
  const { ids, pageNumber, hasNextPage, vehicles, breadcrumb, title, variables, baseUrl } = pageContext;

  // console.log({ baseUrl });

  const current =
    variables && variables.query
      ? Object.keys(variables.query).reduce((acc, q) => ({ ...acc, [q]: variables.query[q].value }), {})
      : {};

  const renderPreviousLink = () =>
  {
    let previousLink = null;

    if (!pageNumber) {
      return null;
    }
    if (pageNumber === 1) {
      previousLink = baseUrl || `/car-sales`;
    } else if (pageNumber > 1) {
      previousLink = baseUrl ? `${baseUrl}/${pageNumber - 1}` : `/car-sales/${pageNumber - 1}`;
    }

    return <Button type="button" onClick={() => navigate(previousLink)} color="accent-1" label="Previous" />;
  };

  const renderNextLink = () =>
  {
    if (hasNextPage) {
      return (
        <Button
          type="button"
          onClick={() => navigate(`${baseUrl || `/car-sales`}/${pageNumber + 1}`)}
          color="accent-1"
          label="Next"
        />
      );
    }
    return null;
  };
  return (
    <Layout>
      <SEO title="Car Sales" />
      <Wrapper>
        <Breadcrumbs parts={breadcrumb} />
        {title && <h1>{title}</h1>}

        <TwoColumn>
          <Box gridArea="filters">
            <FullSearch current={current} />
          </Box>
          <Box gridArea="main">
            {vehicles && vehicles.map(vehicle => <ListingCard vehicle={vehicle} key={vehicle.id} />)}
            <StyledPagination pad="medium" width="100%">
              {renderPreviousLink()}
              {renderNextLink()}
            </StyledPagination>
          </Box>
        </TwoColumn>
      </Wrapper>
    </Layout>
  );
};

export default CarSalesListing;
