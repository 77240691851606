import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) =>
  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      publickey: process.env.GATSBY_ECU_PUBLIC_KEY,
      privatekey: process.env.GATSBY_ECU_PRIVATE_KEY,
    },
  })
);

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetch,
});

export default client;
