import React from 'react';
import { Layer, Box, Button, Heading } from 'grommet';
import styled from 'styled-components';
import { Close } from 'grommet-icons';
import { media } from '../../tokens';

const StyledSpec = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0 0 0 0;
  overflow: hidden;
  overflow-y: auto;
`;
const StyledSpecItem = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 16px;

  @media ${media.small} {
    max-width: 50%;
  }
  @media ${media.medium} {
    max-width: 300px;
  }

  dl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    dt {
      flex: 0 0 35%;
      max-width: 35%;
      margin-bottom: 0;
      padding: 4px;

      &:nth-last-of-type(odd) {
        background-color: #f2f2f2;
      }
    }
    dd {
      flex: 0 0 65%;
      max-width: 65%;
      margin-bottom: 0;
      margin-left: 0;
      padding: 4px;
      &:nth-last-of-type(odd) {
        background-color: #f2f2f2;
      }
    }
  }
`;

const TechSpec = ({ technical, setShowTechSpec }) => (
  <>
    <Layer
      animation="fadeIn"
      responsive
      full="horizontal"
      margin="large"
      modal
      onClickOutside={() => setShowTechSpec(false)}
      onEsc={() => setShowTechSpec(false)}
    >
      <Box fill background="light-1" align="center" justify="center" pad="medium">
        <Box flex={false} direction="row" justify="between" width="100%">
          <Heading level={2} margin="none">
            Full Technical Spec
          </Heading>
          <Button icon={<Close />} onClick={() => setShowTechSpec(false)} />
        </Box>
        <StyledSpec>
          {technical && technical.performance && (
            <StyledSpecItem>
              <h4>Performance</h4>
              <dl>
                <dt>Power</dt>
                <dd>{technical.performance.power.bhp} bhp</dd>
                <dt>Torque</dt>
                <dd>{technical.performance.torque.bhp} ftLb</dd>
                <dt>Max Speed</dt>
                <dd>{technical.performance.maxSpeed.mph} ftLb</dd>
              </dl>
            </StyledSpecItem>
          )}
          {technical && technical.dimensions && (
            <StyledSpecItem>
              <h4>Dimensions</h4>
              <dl>
                <dt>Height</dt>
                <dd>{technical.dimensions.height} mm</dd>
                <dt>Length</dt>
                <dd>{technical.dimensions.length} mm</dd>
                <dt>Width</dt>
                <dd>{technical.dimensions.width} mm</dd>
              </dl>
            </StyledSpecItem>
          )}
          {technical && technical.fuelConsumption && (
            <StyledSpecItem>
              <h4>Fuel Consumption</h4>
              <dl>
                <dt>MPG</dt>
                <dd>{technical.fuelConsumption.mpg} mpg</dd>
                <dt>MPG Cold</dt>
                <dd>{technical.fuelConsumption.mpgCold} mpg</dd>
                <dt>MPG EU</dt>
                <dd>{technical.fuelConsumption.mpgEU} mpg</dd>
              </dl>
            </StyledSpecItem>
          )}
          {technical && technical.emissions && (
            <StyledSpecItem>
              <h4>Emissions</h4>
              <dl>
                <dt>
                  C0<sup>2</sup>
                </dt>
                <dd>{technical.emissions.co2} g/km</dd>
                <dt>Engine Euro Stars</dt>
                <dd>{technical.emissions.engineEuroStars}</dd>
              </dl>
            </StyledSpecItem>
          )}

          {technical && technical.engine && (technical.engine.fuelDelivery || technical.engine.fuelSystem) && (
            <StyledSpecItem>
              <h4>Fuel</h4>
              <dl>
                {!!technical.engine.fuelDelivery && (
                  <>
                    <dt>Fuel Delivery</dt>
                    <dd>{technical.engine.fuelDelivery}</dd>
                  </>
                )}
                {!!technical.engine.fuelSystem && (
                  <>
                    <dt>Fuel System</dt>
                    <dd>{technical.engine.fuelSystem}</dd>
                  </>
                )}
              </dl>
            </StyledSpecItem>
          )}
          {technical && technical.engine && (
            <StyledSpecItem>
              <h4>Engine</h4>
              <dl>
                {technical.engine.arrangement && (
                  <>
                    <dt>Arrangement</dt>
                    <dd>{technical.engine.arrangement}</dd>
                  </>
                )}
                {technical.engine.aspiration && (
                  <>
                    <dt>Aspiration</dt>
                    <dd>{technical.engine.aspiration}</dd>
                  </>
                )}
                {technical.engine.bore && (
                  <>
                    <dt>Bore</dt>
                    <dd>{technical.engine.bore}</dd>
                  </>
                )}
                {technical.engine.camType && (
                  <>
                    <dt>Cam Type</dt>
                    <dd>{technical.engine.camType}</dd>
                  </>
                )}
                {technical.engine.capacity && (
                  <>
                    <dt>Capacity</dt>
                    <dd>{technical.engine.capacity}</dd>
                  </>
                )}
                {technical.engine.cylinders && (
                  <>
                    <dt>Cylinders</dt>
                    <dd>{technical.engine.cylinders}</dd>
                  </>
                )}
                {technical.engine.drivenAxle && (
                  <>
                    <dt>Driven Axle</dt>
                    <dd>{technical.engine.drivenAxle}</dd>
                  </>
                )}

                {technical.engine.gears && (
                  <>
                    <dt>Gears</dt>
                    <dd>{technical.engine.gears}</dd>
                  </>
                )}
                {technical.engine.steeringWheel && (
                  <>
                    <dt>Steering Wheel</dt>
                    <dd>{technical.engine.steeringWheel}</dd>
                  </>
                )}
                {technical.engine.stroke && (
                  <>
                    <dt>Stroke</dt>
                    <dd>{technical.engine.stroke}</dd>
                  </>
                )}
                {technical.engine.valves && (
                  <>
                    <dt>Valves</dt>
                    <dd>{technical.engine.valves}</dd>
                  </>
                )}
              </dl>
            </StyledSpecItem>
          )}
        </StyledSpec>
        {/* {technical &&
              Object.entries(technical).map(tech => (
                <div>
                  {tech[1] && (
                    <>
                      <h3>{tech[0]}</h3>
                      <div>
                        {Object.entries(tech[1]).map(tech2 => (
                          <pre>{JSON.stringify(tech2, null, 2)}</pre>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))} */}

        <p>Disclaimer: ....</p>
        <Button primary label="Close" onClick={() => setShowTechSpec(false)} />
      </Box>
    </Layer>
  </>
);

export default TechSpec;
