import React, { useState, useContext, memo } from 'react';
import { Select, Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const StyledSearch = styled(Box)`
  > * + * {
    ${props => (props.direction === 'row' ? `margin-left: 16px;` : `margin-top: 16px;`)}
  }
`;
const OrderResults: React.FC<any> = memo(({ refetch, query, defaultSort }) =>
{
  // on change refetch
  // on start use default

  const orderOptions = [
    { label: 'By Date added', field: 'createdAt', order: 'DESC' },
    { label: 'By Make A-Z', field: 'makeString', order: 'ASC' },
    { label: 'By Make Z-A', field: 'makeString', order: 'DESC' },
    { label: 'By Price High - Low', field: 'price', order: 'DESC' },
    { label: 'By Price Low - High', field: 'price', order: 'ASC' },
  ];

  const size = useContext(ResponsiveContext);
  const selectedDefault = defaultSort
    ? orderOptions.filter(s => s.field === defaultSort.field && s.order === defaultSort.order)
    : orderOptions[0];

  const [selectedOrder, setSelectedOrder] = useState(selectedDefault);
  if (!query) {
    return;
  }

  const handleChangeOrder = option =>
  {
    setSelectedOrder(option);

    const newQ = {
      query,
      sort: {
        field: option.field,
        order: option.order,
      },
    };

    refetch(newQ);
  };

  return (
    <StyledSearch
      pad="large"
      direction="row-reverse"
      align="center"
      elevation="small"
      margin={{ bottom: 'large' }}
      background="light-1"
      animation="slideUp"
      round="small"
    >
      <Select
        options={orderOptions}
        labelKey="label"
        valueKey="label"
        value={selectedOrder}
        placeholder="Make"
        onChange={({ option }) => handleChangeOrder(option)}
      />
      {/* {JSON.stringify(selectedOrder, null, 2)} */}
    </StyledSearch>
  );
});

export default OrderResults;
