import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button, Box, Heading } from 'grommet';
import Img from 'gatsby-image';
import slugGen from '../../../gatsby/slugGen';
import { StyledLink } from '../ui';

const StyledCard = styled(Box)``;

const StyledCardContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
`;
const StyledCardHeading = styled.h2``;

const StyledCardFigure = styled.figure`
  width: 100%;
  height: auto;
  margin: 0;

  img,
  svg {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
`;

const StyledCardInfoContainer = styled.div`
  padding: 16px;
`;

const StyledCardAction = styled.div`
  padding-top: 16px;
`;

const StyledCardPriceColumn = styled.div`
  padding: 0 16px;
  width: 100%;
  margin-top: auto;
  font-weight: 700;
`;

const FeaturedCard = ({ vehicle }) =>
{
  const { mediaCount, media, year, ageIdentifier, make, model, sold, price, variant } = vehicle;

  const mainPhoto = media.length
    ? `https://res.cloudinary.com/hyperengine/${media[0].resourceType}/upload/w_500,h_400,c_fill,f_auto/${media[0].id}`
    : null;

  const slug = `/${slugGen([
    `${vehicle.vehicleType ? vehicle.vehicleType : 'car'}-sales`,
    vehicle.make.name,
    vehicle.model.name,
    vehicle.slug || `${vehicle.variant}-${vehicle.id}`,
  ])}`;

  return (
    <StyledCard direction="column" elevation="small" pad="medium" alignSelf="stretch" round="xsmall">
      <StyledCardContent>
        <StyledCardFigure>
          <Link to={slug}>
            {vehicle.media.length ? (
              <>
                <Img
                  fluid={vehicle.media[0].imageFile.childImageSharp.fluid}
                  alt={`${vehicle.make.name} ${vehicle.model.name}`}
                />
              </>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width={640} height={480} viewBox="0 0 640 480">
                  <rect x={0} y={0} rx={0} ry={0} width={640} height={480} fill="#f2f2f2" />
                </svg>
              )}
          </Link>
        </StyledCardFigure>
        <StyledCardInfoContainer>
          <Heading level="2" size="small">
            <StyledLink to={slug}>
              {year && <>{year}</>}
              {ageIdentifier && <> ({ageIdentifier})</>} {make.name} {model.name} {variant}
            </StyledLink>
          </Heading>
        </StyledCardInfoContainer>
        {price && (
          <StyledCardPriceColumn>{sold ? <div>SOLD £{price}</div> : <div>£{price}</div>}</StyledCardPriceColumn>
        )}
        <StyledCardAction>
          <Link to={slug}>
            <Button primary label="View" />
          </Link>
        </StyledCardAction>
      </StyledCardContent>
    </StyledCard>
  );
};

export default FeaturedCard;
