import React, { useContext } from 'react';
import styled from 'styled-components';
import QuickSearch from '../vehicles/QuickSearch';
import { ResponsiveContext } from 'grommet';

const StyledBanner = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 5px solid ${props => props.theme.global.colors.brand};
`;
const StyledVideo = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 10px solid ${props => props.theme.global.colors.brand};
`;
const StyledVideoSearch = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  min-width: 270px;
`;
const StyledFrame = styled.div`
min-height: 300px;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    z-index: 7;
  }

  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    z-index: 8;
  }
`;

interface VideoBannerProps
{
  videoId: string;
  showSearch?: boolean;
  fallback?: JSX.Element;
}

export const VideoBanner: React.FC<VideoBannerProps> = ({ videoId, fallback, showSearch = false }) =>
{
  const size = useContext(ResponsiveContext);

  if (size === 'small' && fallback) {
    return (<StyledBanner>
      <StyledFrame>
        {fallback}
      </StyledFrame>
      {
        showSearch && (
          <StyledVideoSearch>
            <QuickSearch />
          </StyledVideoSearch>
        )
      }
    </StyledBanner>)
  }

  return (
    <StyledVideo>
      <StyledFrame>
        <iframe
          width={560}
          height={315}
          src={`https://www.youtube.com/embed/${videoId}?rel=0&controls=0&showinfo=0&autoplay=1&mute=1&loop=1&playlist=${videoId}`}
          frameBorder={0}
          allow="autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
        />
      </StyledFrame>
      {showSearch && (
        <StyledVideoSearch>
          <QuickSearch />
        </StyledVideoSearch>
      )}
    </StyledVideo>
  );
};

export default VideoBanner;
