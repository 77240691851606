import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';

import Breadcrumbs from '../components/ui/Breadcrumbs';
import TechSpec from '../components/vehicles/TechSpec';
import Details from '../components/vehicles/Details';
import VehicleEnquiry from '../components/vehicles/VehicleEnquiry';
import BookAppointment from '../components/vehicles/BookAppointment';

const Vehicles = ({ pageContext: { vehicle, breadcrumb } }) =>
{
  const [showTechSpec, setShowTechSpec] = useState();
  const [showVehicleEnquiry, setShowVehicleEnquiry] = useState();
  const [showBookAppointment, setShowBookAppointment] = useState();

  const {
    id,
    mediaCount,
    media,
    year,
    ageIdentifier,
    desc,
    make,
    model,
    color,
    doors,
    transmission,
    mileage,
    bodyStyle,
    sold,
    price,
    technical,
    features,
    fuelType,
    variant,
    branch,
    dealer,
  } = vehicle;

  return (
    <Layout>
      <SEO title={`${make.name} ${model.name}`} />
      <Wrapper>
        <Breadcrumbs parts={[...breadcrumb, { label: variant, slug: null }]} />

        <Details
          vehicle={vehicle}
          showTechSpec={showTechSpec}
          setShowTechSpec={setShowTechSpec}
          showVehicleEnquiry={showVehicleEnquiry}
          setShowVehicleEnquiry={setShowVehicleEnquiry}
          showBookAppointment={showBookAppointment}
          setShowBookAppointment={setShowBookAppointment}
        />
        {/* <pre>{JSON.stringify(vehicle, null, 2)}</pre> */}
      </Wrapper>
      {showTechSpec && <TechSpec technical={technical} setShowTechSpec={setShowTechSpec} />}
      {showVehicleEnquiry && (
        <VehicleEnquiry
          vehicleID={id}
          dealer={dealer.id}
          branch={branch}
          setShowVehicleEnquiry={setShowVehicleEnquiry}
        />
      )}
      {showBookAppointment && (
        <BookAppointment
          vehicleID={id}
          dealer={dealer.id}
          branch={branch}
          setShowVehicleEnquiry={setShowVehicleEnquiry}
          setShowBookAppointment={setShowBookAppointment}
        />
      )}
    </Layout>
  );
};

export default Vehicles;
