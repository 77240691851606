import React, { useContext } from 'react';
import { Box, Button, Grid, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { Contact, Print, Schedule } from 'grommet-icons';
import { hyperFeatures } from 'hyper-utils';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { DetailListItem } from './DetailList';
import Gallery from './Gallery';
import slugGen from '../../../gatsby/slugGen';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
`;
const StyledActionListWrap = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 65px;
  z-index: 10;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: ${props => props.theme.global.breakpoints.small.value}px) {
    position: static;
    box-shadow: none;
  }
`;
const StyledActionList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
`;
const StyledActionListItem = styled.li`
  margin: 0 8px;
  list-style: none;
`;

const StyledList = styled.dl`
  padding: 0;
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  dt {
    flex: 0 0 32%;
    max-width: 32%;
    margin-bottom: 0;
    padding: 4px;
    font-weight: 700;
  }
  dd {
    flex: 0 0 64%;
    max-width: 64%;
    margin-bottom: 0;
    padding: 4px;
    margin-left: 0;
  }
`;
const StyledFeatures = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 16px 0;
  list-style: none;
`;

const StyledFeaturesItem = styled.li`
  margin: 8px;
  list-style: none;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff;
`;

const Details: React.FC<any> = ({
  vehicle,
  showTechSpec,
  setShowTechSpec,
  showVehicleEnquiry,
  setShowVehicleEnquiry,
  showBookAppointment,
  setShowBookAppointment,
}) =>
{
  const {
    id,
    mediaCount,
    media,
    year,
    ageIdentifier,
    desc,
    make,
    model,
    color,
    doors,
    transmission,
    mileage,
    bodyStyle,
    sold,
    price,
    technical,
    features,
    fuelType,
    variant,
    branch: branchId,
    dealer,
  } = vehicle;

  const size = useContext(ResponsiveContext);

  let mappedFeatures;

  if (vehicle && features.length) {
    mappedFeatures = vehicle.features.map(
      feat => hyperFeatures.CAR_FEATURES.filter(item => item.key === feat)[0].label
    );
  }

  const currentBranch = branchId && dealer.branches.find(branch => branch.id === branchId);

  return (
    <Grid
      columns={size === 'small' ? ['1fr'] : ['3fr', '2fr']}
      rows={size === 'small' ? ['auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', 'auto', 'auto']}
      gap={size === 'small' ? 'small' : 'medium'}
      fill="horizontal"
      areas={
        size === 'small'
          ? [
            { name: 'header', start: [0, 0], end: [0, 0] },
            { name: 'nav', start: [0, 1], end: [0, 1] },
            { name: 'media', start: [0, 2], end: [0, 2] },
            { name: 'main', start: [0, 3], end: [0, 3] },
            { name: 'foot', start: [0, 4], end: [0, 4] },
          ]
          : [
            { name: 'header', start: [0, 0], end: [1, 0] },
            { name: 'nav', start: [0, 1], end: [1, 1] },
            { name: 'media', start: [0, 2], end: [0, 2] },
            { name: 'main', start: [1, 2], end: [1, 2] },
            { name: 'foot', start: [0, 3], end: [1, 3] },
          ]
      }
    >
      <Box gridArea="header" pad="xxsmall">
        <StyledHeader>
          <Heading level={1} responsive margin={{ bottom: 'xsmall' }}>
            {make.name} {model.name}
          </Heading>
          <Heading level={2} responsive margin={{ bottom: 'xxsmall' }}>
            {variant} {year && <>{year}</>} {ageIdentifier && <>{ageIdentifier}</>}
          </Heading>
          {sold && <div>SOLD £{price}</div>}
          {price && !sold && <div>£{price}</div>}
        </StyledHeader>
      </Box>
      <StyledActionListWrap gridArea="nav" background="light-5" pad="small">
        <StyledActionList>
          <StyledActionListItem>
            {size === 'small' ? (
              <Button
                primary
                type="button"
                icon={<Contact />}
                alignSelf="stretch"
                plain={false}
                onClick={() => setShowVehicleEnquiry(true)}
              />
            ) : (
                <Button
                  primary
                  type="button"
                  icon={<Contact />}
                  alignSelf="stretch"
                  label="Enquire Online"
                  onClick={() => setShowVehicleEnquiry(true)}
                />
              )}
          </StyledActionListItem>
          <StyledActionListItem>
            {size === 'small' ? (
              <Button
                type="button"
                icon={<Schedule />}
                plain={false}
                alignSelf="stretch"
                onClick={() => setShowBookAppointment(true)}
              />
            ) : (
                <Button
                  type="button"
                  icon={<Schedule />}
                  alignSelf="stretch"
                  label="Book Appointment"
                  onClick={() => setShowBookAppointment(true)}
                />
              )}
          </StyledActionListItem>
          {/* <StyledActionListItem>
            {size === 'small' ? <Button type="button" icon={<Share />} plain={false} alignSelf="stretch" /> : <Button type="button" icon={<Share />} alignSelf="stretch" label="Share" />}
          </StyledActionListItem> */}
          {size === 'large' && (
            <StyledActionListItem>
              <Button
                href={`https://pdf.hyperengine.dev/datasheet/${id}`}
                alignSelf="stretch"
                target="_blank"
                label="Print"
                icon={<Print />}
              />
            </StyledActionListItem>
          )}
        </StyledActionList>
      </StyledActionListWrap>
      <Box gridArea="media" background="light-5" pad="small">
        {media && media.length > 0 && <Gallery images={media} />}
      </Box>
      <Box gridArea="main" background="light-2" pad="small">
        <h2>Vehicle Details</h2>
        <StyledList>
          {bodyStyle && <DetailListItem label="Bodystyle" value={bodyStyle} />}
          {mileage && <DetailListItem label="Mileage" value={mileage} after="miles" />}
          {transmission && <DetailListItem label="Transmission" value={transmission} />}
          {technical && <DetailListItem label="Capacity" value={technical.engine.capacity} after="CC" />}
          {technical && <DetailListItem label="MPG" value={technical.fuelConsumption.mpg} after="mpg" />}
          {technical && <DetailListItem label="c02" value={technical.emissions.co2} after="g/km" />}
          {fuelType && <DetailListItem label="Fuel type" value={fuelType} />}
          {color && <DetailListItem label="Colour" value={color} />}
          {doors && <DetailListItem label="Doors" value={doors} />}
        </StyledList>
        <Button primary type="button" label="View full spec" onClick={() => setShowTechSpec(true)} />
        {mappedFeatures && (
          <>
            <h2>Vehicle Features</h2>
            <StyledFeatures>
              {mappedFeatures.map(f => (
                <StyledFeaturesItem key={f}>{f}</StyledFeaturesItem>
              ))}
            </StyledFeatures>
          </>
        )}
      </Box>
      <Box gridArea="foot" background="light-2" pad="small">
        <Paragraph fill>{desc}</Paragraph>

        {currentBranch && (
          <>
            <Paragraph>
              This vehicle is located at:{' '}
              <Link to={slugGen([`branches`, currentBranch.name])}>{currentBranch.name}</Link>
            </Paragraph>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default Details;
