import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../tokens';

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}

`;
export const StyledLink = styled(Link)`
  border-bottom: 1px dotted ${colors.primary};

  &:hover {
    border-bottom-style: solid;
  }
`;

export const Text = styled.p`
  line-height: 1.6;
  margin: 1em 0 0 0;
`;
