import React from 'react';
import { Heading } from 'grommet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';

import ContactForm from '../components/forms/contact';

const Contact = () => (
    <Layout>
      <SEO title="Contact Us" />
      <Wrapper>
        <Heading>Contact Us</Heading>

        <ContactForm />
      </Wrapper>
    </Layout>
  );

export default Contact;
