import React, { useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';

const Wrapper = ({ children }) =>
{
  const size = useContext(ResponsiveContext);
  return (
    <Box direction="column" pad={size} width={{
      max: '1700px'
    }} alignSelf="center"

      style={{
        width: '95%'
      }}>
      {children}
    </Box >
  )
};

export default Wrapper;
