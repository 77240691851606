import React from 'react';
import { Anchor, Box, ResponsiveContext, Text } from 'grommet';

import styled from 'styled-components';
import { Link } from 'gatsby';
import useSiteMetadata from '../../../hooks/use-site-config';

const StyledAnchor = styled(Anchor)`
  font-weight: 200;
  text-decoration: none;
  color: #222;
`;

const FooterColumn = ({ column, id }) => (
  <Box gap="medium" key={column.sectionName}>
    <Text weight="bold" size="small">
      {column.sectionName}
    </Text>
    {column.links.map((item, i) => (
      <>
        {item.url.startsWith('http') ? (
          <StyledAnchor href={item.url} as="a" size="small" color="black" key={`${id}-footer-ci-${i}`}>
            {item.label}
          </StyledAnchor>
        ) : (
          <StyledAnchor as={Link} size="small" color="black" to={item.url} key={`${id}-footer-ci-${i}`}>
            {item.label}
          </StyledAnchor>
        )}
      </>
    ))}
  </Box>
);

const FooterContent = () => {
  const { footerLinks } = useSiteMetadata();

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box direction="row" gap={size !== 'xsmall' && size !== 'small' ? 'xlarge' : 'small'}>
          {footerLinks.map((column, i) => (
            <FooterColumn column={column} key={`footer-column-${column.sectionName}-${i}`} id={i} />
          ))}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export { FooterContent };
