import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const AllImages = () =>
{
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid {
              originalImg
              originalName
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `);

  return null;
};

export default AllImages;
