const slugify = require('slugify');

module.exports = parts =>
  parts
    .filter(part => !!part)
    .map(part => {
      const thePart = Array.isArray(part) ? part.join('') : part;
      return slugify(`${thePart}`, {
        replacement: '-', // replace spaces with replacement
        lower: true, // result in lower case
      });
    })
    .join('/');
