import React, { useContext } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import styled from 'styled-components';
import { ResponsiveContext, Grid } from 'grommet';

const StyledMap = styled.div`
  .leaflet-container {
    height: 400px;
    width: 100%;
    @media (min-width: ${props => props.theme.global.breakpoints.small.value}px) {
      height: 700px;
    }
  }
`;
const Makes = ({ pageContext: { branch, dealer } }) =>
{
  const size = useContext(ResponsiveContext);
  const { name, email, phone, address } = branch;
  const { name: dealerName, tradingName } = dealer;
  const state = {
    lat: 51.505,
    lng: -0.09,
    zoom: 13,
  };
  const position = [state.lat, state.lng];
  return (
    <Layout>
      <SEO title={`${name} branch at ${tradingName}`} />
      <Wrapper>
        <Grid columns={size === 'small' ? ['1fr'] : ['1fr', '2fr']} gap="medium" fill="horizontal">
          <div>
            <h1>{name}</h1>
            <p>Call us: {phone}</p>
            <p>
              <a href={`mailto:${email}`}>{email}</a>
            </p>

            <address>
              {address.line1 && (
                <>
                  <span>{address.line1}</span>
                  <br />
                </>
              )}
              {address.line2 && (
                <>
                  <span>{address.line2}</span>
                  <br />
                </>
              )}
              {address.line3 && (
                <>
                  <span>{address.line3}</span>
                  <br />
                </>
              )}
              {address.town && (
                <>
                  <span>{address.town}</span>
                  <br />
                </>
              )}
              {address.county && (
                <>
                  <span>{address.county}</span>
                  <br />
                </>
              )}
              {address.postcode && (
                <>
                  <span>{address.postcode}</span>
                  <br />
                </>
              )}
            </address>
            {/* <pre>{JSON.stringify(branch, null, 2)}</pre> */}
          </div>
          <StyledMap>
            {typeof window !== 'undefined' &&

              <Map center={position} zoom={state.zoom}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  <Popup>
                    <h3>{name}</h3>
                    <p>Call us: {phone}</p>
                  </Popup>
                </Marker>
              </Map>
            }
          </StyledMap>
        </Grid>
      </Wrapper>
    </Layout>
  );
};

export default Makes;
