import React from 'react';
import styled from 'styled-components';
import StyledLink from './StyledLink';

const StyledBreadcrumb = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  overflow-x: auto;
`;
const StyledBreadcrumbItem = styled.li`
  flex-shrink: 0;
  & + & {
    &:before {
      content: '/';

      display: inline-flex;
      margin: 0 8px;
    }
  }
`;

const BreadcrumbItem = ({ item }) => {
  if (item.slug) {
    return (
      <StyledBreadcrumbItem>
        <StyledLink to={item.slug}>{item.label}</StyledLink>
      </StyledBreadcrumbItem>
    );
  }
  return (
    <StyledBreadcrumbItem>
      <strong>{item.label}</strong>
    </StyledBreadcrumbItem>
  );
};

const Breadcrumbs = ({ parts }) => {
  if (!parts) {
    return null;
  }
  return (
    <StyledBreadcrumb>
      <>
        <BreadcrumbItem item={{ label: 'Home', slug: '/' }} />
        {parts && parts.map((part, i) => <BreadcrumbItem key={i} item={part} />)}
      </>
    </StyledBreadcrumb>
  );
};

export default Breadcrumbs;
