import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button, Box } from 'grommet';
import styled from 'styled-components';
import withLocation from '../../hocs/with-location';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Wrapper from '../../components/Wrapper';
import FullSearch from '../../components/vehicles/FullSearch';
import ListingCard from '../../components/vehicles/ListingCard';
import OrderResults from '../../components/vehicles/OrderResults';
import { TwoColumn } from '../../components/ui';

const StyledPagination = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-child {
    margin-left: auto;
  }
`;

const SEARCH_QUERY = gql`
  query getVehicles($query: VehicleQueryInput, $sort: VehicleSortInput) {
    vehicles(limit: 10, query: $query, sort: $sort) {
      hasNext
      hasPrevious
      next
      previous
      results {
        model {
          name
        }
        make {
          name
        }
        makeString
        mediaCount
        modelString
        price
        ageIdentifier
        desc
        title
        mileage
        slug

        year
        vehicleType
        variant
        bodyStyle
        color

        doors

        fuelType
        technical {
          electricVehicleBattery {
            capacity
            chargePort
            chargeTime
            batteryType
          }
          performance {
            torque {
              ftLb
              nm
              peak
            }
            power {
              bhp
              kw
              peak
            }
            maxSpeed {
              mph
              kph
            }
          }
          dimensions {
            height
            width
            length
          }
          emissions {
            co2
            engineEuroStars
          }
          engine {
            fuelSystem
            fuelDelivery
            aspiration
            cylinders
            camType
            drivenAxle
            arrangement
            capacity
            gears
            steeringWheel
            wheelbase
            valves
            engineLocation
            stroke
            bore
          }
          fuelConsumption {
            mpg
            mpgEU
            mpgCold
          }
        }
        media {
          id
          url
          type
          resourceType
        }
        id
      }
    }
  }
`;

interface queryItem
{
  value: string | number;
  operator: string;
}
interface searchQuery
{
  make?: queryItem;
  model?: queryItem;
  priceFrom?: queryItem;
  priceTo?: queryItem;
  priceRange?: any;
  transmission?: queryItem;
  fuelType?: queryItem;
  bodyStyle?: queryItem;
  deleted?: queryItem;
}

const SearchPage: React.FC<any> = ({ search }) =>
{
  const { make, model, priceFrom, priceTo, page: pageNumber, transmission, fuelType, bodyStyle } = search;

  const baseSearch = search;
  delete baseSearch.page;

  const baseQ = Object.keys(baseSearch)
    .map(key => `${key}=${baseSearch[key]}`)
    .join('&');

  const query: searchQuery = {
    deleted: { value: false },
  };

  if (make) {
    query.make = { value: make, operator: 'eq' };
  }

  if (model) {
    query.model = { value: model, operator: 'eq' };
  }
  if (fuelType) {
    query.fuelType = { value: fuelType, operator: 'eq' };
  }
  if (bodyStyle) {
    query.bodyStyle = { value: bodyStyle, operator: 'eq' };
  }

  if (priceFrom && priceTo) {
    query.priceRange = { min: +priceFrom, max: +priceTo };
  } else if (priceFrom) {
    query.priceFrom = { value: +priceFrom, operator: 'gte' };
  } else if (priceTo) {
    query.priceTo = { value: +priceTo, operator: 'lte' };
  }

  const sort = {
    field: 'price',
    order: 'DESC',
  };

  const { loading, error, data, refetch } = useQuery(SEARCH_QUERY, {
    variables: {
      query,
      sort,
    },
  });

  const renderPreviousLink = () =>
  {
    let previousLink = null;

    if (!pageNumber) {
      return null;
    }
    if (pageNumber === 1) {
      previousLink = `/search/?${baseQ}`;
    } else if (pageNumber > 1) {
      previousLink = `/search/?${baseQ}&page=${+pageNumber - 1}`;
    }

    return <Button type="button" onClick={() => navigate(previousLink)} primary label="Previous" />;
  };

  const renderNextLink = () =>
  {
    if (data && data.vehicles.hasNext) {
      return (
        <Button
          type="button"
          onClick={() => navigate(`/search/?${baseQ}&page=${pageNumber ? +pageNumber + 1 : 2}`)}
          primary
          label="Next"
        />
      );
    }
    return null;
  };

  return (
    <Layout>
      <SEO title="Vehicle Search" />

      <Wrapper>
        <h1>Vehicle Search</h1>

        <TwoColumn>
          <Box gridArea="filters">
            <FullSearch search={{ make, model }} />
          </Box>

          <Box gridArea="main">
            <OrderResults refetch={refetch} query={query} defaultSort={sort} />
            {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
            {loading && <p>Loading</p>}
            {!loading &&
              data &&
              data.vehicles.results &&
              data.vehicles.results.map(vehicle => <ListingCard vehicle={vehicle} search key={vehicle.id} />)}
          </Box>
        </TwoColumn>
        <StyledPagination pad="medium" width="100%">
          {renderPreviousLink()}
          {renderNextLink()}
        </StyledPagination>
      </Wrapper>
    </Layout>
  );
};

export default withLocation(SearchPage);
