import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ResponsiveContext } from 'grommet';
import Layout from '../components/layout';

import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';

import BranchList from '../components/BranchList';

const BranchListing = () =>
{
  const data = useStaticQuery(graphql`
    query GET_MY_BRANCHESSTATIC {
      hyper {
        myDealer {
          name
          tradingName

          branches {
            id
            name
            email
            phone
            description
            address {
              line1
              line2
              line3
              county
              town
              postcode
              lat
              lng
            }
          }
        }
      }
    }
  `);

  const results = data.hyper.myDealer;

  return (
    <Layout>
      <SEO title="Branches" />
      <Wrapper>
        <h1>Branch List</h1>
        {results && results.branches && results.branches.length > 0 && <BranchList branches={results.branches} />}
      </Wrapper>
    </Layout>
  );
};

export default BranchListing;
