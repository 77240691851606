import React from 'react';
import { Layer, Box, Button, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import VehicleForm from '../forms/vehicle';

const BookAppointment = ({ vehicleID, setShowBookAppointment, branch, dealer }) => (
  <>
    <Layer
      position="right"
      full="vertical"
      modal
      onClickOutside={() => setShowBookAppointment(false)}
      onEsc={() => setShowBookAppointment(false)}
    >
      <Box fill="vertical" overflow="auto" width="large" pad="medium">
        <Box flex={false} direction="row" justify="between">
          <Heading level={2} margin="none">
            Book Appointment
          </Heading>
          <Button icon={<Close />} onClick={() => setShowBookAppointment(false)} />
        </Box>

        <VehicleForm vehicleID={vehicleID} showDate branch={branch} dealer={dealer} />
      </Box>
    </Layer>
  </>
);

export default BookAppointment;
