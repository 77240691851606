import React, { useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Img from 'gatsby-image';

const CustomGalleryItem = ({ item }) => (
  <div className="image-gallery-image">
    <Img fluid={item.imageFile.childImageSharp.fluid} />
  </div>
);
const buildImages = images =>
  images.map(item => ({
    item,
    // original: `https://res.cloudinary.com/hyperengine/${item.resourceType}/upload/w_970,h_720,c_fill/${item.id}`,
    thumbnail: `https://res.cloudinary.com/hyperengine/${item.resourceType}/upload/w_250,h_150,c_fill,f_auto/${item.id}`,
    renderItem: CustomGalleryItem,
  }));

const Gallery = ({ images }) =>
{
  const mappedImages = useMemo(() => buildImages(images), [images]);
  return <ImageGallery items={mappedImages} showFullscreenButton={false} />;
};

export default Gallery;
