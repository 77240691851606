import React from 'react';

export interface ListItemProps {
  label: any;
  value: any;
  after?: any;
}
export const DetailListItem: React.FC<ListItemProps> = ({ label, value, after }) => {
  if (!value) {
    return null;
  }
  return (
    <>
      <dt>{label}</dt>
      <dd>
        {value} {after && <> {after}</>}
      </dd>
    </>
  );
};
