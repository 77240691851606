import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Wrapper from "../components/Wrapper"
import Layout from "../components/layout"
import { StyledCMS } from "../components/styles"

export const pageQuery = graphql`
  query GET_PAGE($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        uri
      }
    }
  }
`



const Page = props =>
{
  const {
    location,
    data: {
      wpgraphql: { page },
    },
  } = props
  const { title, content } = page
  return (
    <Layout>

      <Wrapper>
        <Seo title={`${page.title}`} />

        <h1>{title}</h1>

        <StyledCMS dangerouslySetInnerHTML={{ __html: content }} />

      </Wrapper>
    </Layout>
  )
}

export default Page

