import React, { useContext } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet';
import slugGen from '../../gatsby/slugGen';
import { Link } from 'gatsby';


const BranchList = ({ branches }) =>
{

  if (branches.length <= 0) {
    console.warn('no branches');
    return;
  }
  const size = useContext(ResponsiveContext);
  return (
    <>
      <Grid columns={size === 'small' ? ['1fr'] : ['1fr', '1fr', '1fr']} gap="medium" fill="horizontal">

        {branches.map(({ name, address }, i) =>
        {
          const slug = slugGen([`branches`, name]);


          return (
            <Box
              key={i}
              direction="column"
              elevation="small"
              pad="medium"
              alignSelf="stretch"
              round="xsmall"
              background="light-3"
            >
              <Link to={`/${slug}`}>{name}</Link>
              <address>
                {address.line1 && <span>{address.line1}<br /></span>}
                {address.line2 && <span>{address.line2}<br /></span>}
                {address.line3 && <span>{address.line3}<br /></span>}
                {address.town && <span>{address.town}<br /></span>}
                {address.county && <span>{address.county}<br /></span>}
                {address.postcode && <span>{address.postcode}</span>}
              </address>
              {address.phone && <span>{address.phone}</span>}

            </Box>
          );
        })}


      </Grid>

    </>
  )
}



export default BranchList
