import React, { useState } from 'react';
import { Box, FormField, TextInput, Button, TextArea } from 'grommet';
import { Formik } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';
import { TwoColumn } from '../ui';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const data = useStaticQuery(graphql`
    query GET_MY_DEALER_STATIC {
      hyper {
        myDealer {
          contact {
            phone
            address {
              line1
              line2
              line3
              lng
              county
              lat
              postcode
              town
            }
            email
          }
        }
      }
    }
  `);

  const {
    contact: { address },
    contact,
  } = data.hyper.myDealer;

  return (
    <TwoColumn>
      <Box pad="small">
        {contact && address && (
          <>
            <address>
              {address.line1 && (
                <span>
                  {address.line1}
                  <br />
                </span>
              )}
              {address.line2 && (
                <span>
                  {address.line2}
                  <br />
                </span>
              )}
              {address.line3 && (
                <span>
                  {address.line3}
                  <br />
                </span>
              )}
              {address.town && (
                <span>
                  {address.town}
                  <br />
                </span>
              )}
              {address.county && (
                <span>
                  {address.county}
                  <br />
                </span>
              )}
              {address.postcode && <span>{address.postcode}</span>}
            </address>
            {address.phone && <span>{address.phone}</span>}
          </>
        )}
      </Box>
      <Box pad="medium">
        <Formik
          validate={values => {
            const errors = {};

            if (!values.name) {
              errors.name = 'required';
            }

            return errors;
          }}
          validateOnBlur={submitted}
          validateOnChange={submitted}
          onSubmit={(values, { setSubmitting }) => {
            // whatever submitting the form should entail
            alert(`Submitting\n${JSON.stringify(values, null, 2)}`);
            setSubmitting(true);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <form
              onSubmit={event => {
                event.preventDefault();
                setSubmitted(true);
                handleSubmit();
              }}
            >
              <FormField label="Name" error={errors.name}>
                <TextInput name="name" value={values.name || ''} onChange={handleChange} />
              </FormField>
              <FormField label="Email" error={errors.email}>
                <TextInput name="email" type="email" value={values.email || ''} onChange={handleChange} />
              </FormField>
              <FormField label="Comments" error={errors.comments}>
                <TextArea name="comments" value={values.comments || ''} onChange={handleChange} />
              </FormField>
              <Box tag="footer" margin={{ top: 'medium' }} direction="row" justify="between">
                <Button type="submit" primary label="Send" />
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </TwoColumn>
  );
};

export default ContactForm;
