/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Grommet } from 'grommet';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GlobalStyle } from './Commons';
import theme, { media } from '../tokens';
import Header from './header';
import AllImages from './AllImages';
import { Footer } from './ui';

const SiteContent = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.medium} {
    margin: 60px 0;
  }
`;

const Layout = ({ children }) =>
{
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Grommet theme={theme} full>
      <>
        <Helmet>
          {/* <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet" /> */}
          <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
        </Helmet>
        <AllImages />
        <GlobalStyle />

        <Header />

        <SiteContent>{children}</SiteContent>
        <Footer />
      </>
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
