import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
export default StyledLink;
