import React, { useState } from 'react';
import { Box, Form, FormField, TextInput, Button, TextArea, Heading, Calendar } from 'grommet';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const ADD_ENQ = gql`
  mutation addEnquiry($enquiry: EnquiryInput!) {
    addEnquiry(enquiry: $enquiry) {
      status
      message
    }
  }
`;

const VehicleForm = ({ vehicleID, dealer, branch, showDate, subject }) =>
{
  const [submitted, setSubmitted] = useState(false);

  const [addEnq, { data, loading, error }] = useMutation(ADD_ENQ);

  if (!loading && data && data.addEnquiry && data.addEnquiry.status) {
    return <p>Enquiry Sent</p>;
  }
  return (
    <Box align="center">
      <Box width="medium" margin="large">
        <Formik
          validate={values =>
          {
            const errors = {};

            if (!values.name) {
              errors.name = 'required';
            }
            if (!values.email) {
              errors.email = 'required';
            }

            return errors;
          }}
          validateOnBlur={submitted}
          validateOnChange={submitted}
          onSubmit={(values, { setSubmitting }) =>
          {
            // whatever submitting the form should entail
            // alert('Submitting\n' + JSON.stringify(values, null, 2));

            const enq = {
              group: 'VEHICLE',
              fromName: values.name,
              fromEmail: values.email,
              subject,
              dealer,
              branch,
              vehicle: vehicleID,
              phone: values.phone,
              body: `Phone: ${values.phone} 
              ${values.comments}
              `,
            };
            addEnq({
              variables: {
                enquiry: enq,
              },
            });
            setSubmitting(true);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue, resetForm }) => (
            <form
              onSubmit={event =>
              {
                event.preventDefault();
                setSubmitted(true);
                handleSubmit();
              }}
            >
              <FormField label="Name" error={errors.name}>
                <TextInput name="name" value={values.name || ''} onChange={handleChange} />
              </FormField>
              <FormField label="Email" error={errors.email}>
                <TextInput name="email" type="email" value={values.email || ''} onChange={handleChange} />
              </FormField>
              <FormField label="Phone" error={errors.phone}>
                <TextInput name="phone" type="text" value={values.phone || ''} onChange={handleChange} />
              </FormField>

              {showDate && (
                <Calendar
                  size="medium"
                  heading={{
                    level: '3',
                  }}
                  date={new Date().toISOString()}
                  onSelect={date => { }}
                />
              )}

              <FormField label="Comments" error={errors.comments}>
                <TextArea name="comments" value={values.comments || ''} onChange={handleChange} />
              </FormField>
              <Box tag="footer" margin={{ top: 'medium' }} direction="row" justify="between">
                <Button type="submit" primary label={!error && loading ? 'Sending...' : 'Send'} />
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default VehicleForm;
