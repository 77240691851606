import React, { useContext } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';

import styled from 'styled-components';
import { SocialMedia } from '../SocialMedia';
import { Logo } from '../Logo';
import { FooterContent } from './FooterContent';
import useSiteMetadata from '../../../hooks/use-site-config';

const StyledFooter = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
`;
const Footer = () => {
  const { siteTitle } = useSiteMetadata();
  const size = useContext(ResponsiveContext);
  return (
    <StyledFooter
      direction={size === 'large' || size === 'medium' ? 'row' : 'column'}
      justify="between"
      border={{ side: 'top', color: 'light-4' }}
      pad={{ top: 'large', left: 'medium', right: 'medium', bottom: 'large' }}
    >
      <Box gap="large" align="start">
        <Box
          gap="small"
          direction="row-responsive"
          align="center"
          pad={{ horizontal: 'small' }} // align with margin of icons from SocialMedia
        >
          <Logo />
          {size !== 'small' && size !== 'xsmall' && (
            <Text weight="bold" size="large">
              {siteTitle}
            </Text>
          )}
        </Box>
        <SocialMedia />
      </Box>
      <FooterContent />
    </StyledFooter>
  );
};

export { Footer };
