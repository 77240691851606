// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-branches-tsx": () => import("./../src/pages/branches.tsx" /* webpackChunkName: "component---src-pages-branches-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-branch-tsx": () => import("./../src/templates/Branch.tsx" /* webpackChunkName: "component---src-templates-branch-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-vehicle-tsx": () => import("./../src/templates/Vehicle.tsx" /* webpackChunkName: "component---src-templates-vehicle-tsx" */),
  "component---src-templates-vehicles-tsx": () => import("./../src/templates/Vehicles.tsx" /* webpackChunkName: "component---src-templates-vehicles-tsx" */)
}

