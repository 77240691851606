import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Select, Box, Button, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const StyledSearch = styled(Box)`
  > * + * {
    ${props => (props.direction === 'row' ? `margin-left: 16px;` : `margin-top: 16px;`)}
  }
`;
const QuickSearch: React.FC<any> = ({ search }) => {
  const data = useStaticQuery(graphql`
    query GET_QUICK_SEARCH_DATA {
      hyper {
        myVehicleMakesModels {
          id
          name
          models {
            id
            name
          }
        }
      }
    }
  `);

  const size = useContext(ResponsiveContext);
  const selectedBlank = { name: 'All', id: '' };

  const [selectedMake, setSelectedMake] = useState(selectedBlank);
  const [selectedModel, setSelectedModel] = useState(selectedBlank);
  const [models, setModels] = useState([selectedBlank]);
  // const [selectedMinPrice, setSelectedMinPrice] = useState(undefined);
  // const [selectedMaxPrice, setSelectedMaxPrice] = useState(undefined);

  const makes = data.hyper.myVehicleMakesModels;
  // if (search && search.make) {
  //   const searchedMake = makes.filter(m => m.id === search.make);

  //   if (searchedMake[0]) {
  //     setSelectedMake(searchedMake[0]);
  //   }
  // }

  const handleChangeMake = option => {
    setSelectedMake(option);

    setModels([selectedBlank, ...option.models]);
    setSelectedModel(selectedBlank);
  };

  const handleSearch = () => {
    const buildSearch = `${selectedMake && selectedMake.id ? `make=${selectedMake.id}` : ''}${
      selectedModel && selectedModel.id ? `&model=${selectedModel.id}` : ''
    }`;

    navigate(`/search/?${buildSearch}`);
  };
  return (
    <StyledSearch
      pad="large"
      direction="column"
      align="center"
      elevation="small"
      margin={{ top: 'small', bottom: 'large' }}
      background="light-1"
      animation="slideUp"
      round="small"
    >
      <Select
        options={makes}
        labelKey="name"
        valueKey="id"
        value={selectedMake}
        placeholder="Make"
        onChange={({ option }) => handleChangeMake(option)}
      />
      <Select
        options={models}
        labelKey="name"
        valueKey="id"
        value={selectedModel}
        placeholder="Model"
        onChange={({ option }) => setSelectedModel(option)}
      />

      <Button label="Search" onClick={() => handleSearch()} />
    </StyledSearch>
  );
};

export default QuickSearch;
