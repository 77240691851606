import React, { useContext } from 'react';
import { Grid, ResponsiveContext } from 'grommet';

export const TwoColumn = ({ children }) =>
{
  const size = useContext(ResponsiveContext);

  return (
    <Grid
      columns={size === 'small' || size === 'medium' ? ['full'] : ['300px', 'auto']}
      rows={['auto']}
      gap="small"
      areas={size === 'small' || size === 'medium' ? [['filters'], ['main']] : [['filters', 'main']]}
    >
      {children}
    </Grid>
  );
};

export default TwoColumn;
