import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import colorDefaults from './colors';
import mediaDefaults from './media';
import { base } from './base';
import theme from '../theme';

export const colors = { ...colorDefaults, ...(theme.colors || {}) };
export const media = { ...mediaDefaults, ...(theme.media || {}) };
// export default { colors, media }

export default deepMerge(grommet, base);
